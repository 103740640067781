import { TOKEN } from "./auth-service";
import Vue from "vue";

export default function authHeader() {
    const token = Vue.$cookies.get(TOKEN);

    if (token) {
        return {
            Authorization: `Bearer ${token}`
        };
    } else {
        return {};
    }
}
