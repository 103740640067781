/**
 * GETTERS
 */
export const APP_DRAWER = "app/APP_DRAWER";
export const APP_PROGRESSBAR = "app/APP_PROGRESSBAR";
export const APP_FILTER_LOADING = "app/APP_FILTER_LOADING";
export const APP_INFO_MESSAGE = "app/APP_INFO_MESSAGE";
export const AUTH_USERNAME = "auth/AUTH_USERNAME";
export const USER_ROLE = "auth/USER_ROLE";
export const ALL_ROLES = "auth/ALL_ROLES";
export const USER_IDENTIFIER = "auth/USER_IDENTIFIER";
export const CLIENTE = "auth/CLIENTE";
export const IDPERSONA = "auth/IDPERSONA";
export const IDIOMA = "auth/IDIOMA";
export const LOGO = "auth/LOGO";
export const SERVICIOS = "auth/SERVICIOS";
export const EMPRESA = "auth/EMPRESA";

/**
 * MUTATIONS
 */
export const MUTATE_APP_DRAWER = "app/MUTATE_APP_DRAWER";
export const MUTATE_USER_STATE = "auth/MUTATE_USER_STATE";
export const MUTATE_LOGO = "auth/MUTATE_LOGO";
export const MUTATE_EMPRESA = "auth/MUTATE_EMPRESA";
export const MUTATE_APP_PROGRESSBAR = "app/MUTATE_APP_PROGRESSBAR";
export const MUTATE_APP_FILTER_LOADING = "app/MUTATE_APP_FILTER_LOADING";
export const MUTATE_APP_INFO_MESSAGE = "app/MUTATE_APP_INFO_MESSAGE";

/**
 * ACTIONS
 */
export const AUTH_LOGIN = "auth/AUTH_LOGIN";
export const AUTH_LOGOUT = "auth/AUTH_LOGOUT";
