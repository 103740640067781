import axios from "axios";
import "form-data";
import jwtDecode from "jwt-decode";

import { ResponseData, Token } from "@/interfaces/index";
import Vue from "vue";

/**
 * DEV: Using devserver with a proxy
 */
const API_URL = process.env.VUE_APP_API_URL || window.location.origin + "/api";
export const TOKEN = "acideToken";
export const EXPIRATION_TIME = "acideExpiration";

export const ROLE_ADMIN = "admin";
export const ROLE_CLIENT = "client";

const client = axios.create({
    baseURL: API_URL
});

export type UserFormData = { email: string; password: string } | null;
export type LogoutData = { refresh_token: string };
export type NewUser = {
    identifier: number;
    username: string;
    email: string;
    password: string;
    role: string[];
    cliente: number;
    idpersona: number;
    idioma: string;
    logo: string;
    servicios: string[];
};
export interface UserSigninRes extends ResponseData {
    data: {
        token: string;
    };
}

interface UserSigninResEuskabea extends ResponseData {
    data: {
        id: string;
        username: string;
        email: string;
        role: string[];
        cliente: number;
        idpersona: number;
        idioma: string;
        logo: string;
        servicios: string[];
    };
}

interface GetUserRes extends ResponseData {
    detail?: string;
    title?: string;
}

interface NewUserRes extends ResponseData {
    data: {
        user: {
            identifier: number;
            username: string;
            email: string;
            pasword: string;
            role: string[];
            cliente: number;
            idpersona: number;
            idioma: string;
            logo: string;
            servicios: string[];
        };
    };
}

export interface UserData {
    identifier: number;
    username: string;
    role: Array<string>;
    email: string;
    cliente: number;
    idpersona: number;
    idioma: string;
    logo: string;
    servicios: string[];
    Nempresa: number;
}

export interface UserState {
    status: { loggedIn: boolean };
    user: UserData | null;
}

export const getUserState = (): UserState => {
    const token = decodeURIComponent(document.cookie)
        .split("; ")
        .filter(c => {
            if (c.indexOf(TOKEN + "=") !== -1) {
                return c;
            }
        });

    let userData: UserData | null = null;

    if (token.length) {
        const tokenInfo: {
            identifier: number;
            username: string;
            email: string;
            role: Array<string>;
            cliente: number;
            idpersona: number;
            idioma: string;
            logo: string;
            servicios: Array<string>;
        } = jwtDecode(token[0].split("=")[1]);

        userData = {
            identifier: tokenInfo.identifier,
            username: tokenInfo.username,
            email: tokenInfo.email,
            role: tokenInfo.role,
            cliente: tokenInfo.cliente,
            idpersona: tokenInfo.idpersona,
            idioma: tokenInfo.idioma,
            logo: tokenInfo.logo,
            servicios: tokenInfo.servicios,
            Nempresa: 0
        };
    }

    const userState: UserState = userData
        ? { status: { loggedIn: true }, user: userData }
        : { status: { loggedIn: false }, user: null };

    return userState;
};

class AuthService {
    async loginEuskabea(email, password) {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("clave", password);

        return client
            .request({
                method: "post",
                url:
                    "https://sat.euskabea.com/webservices/login.php?key=003026bASD714df1834b8638bb496e",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" }
            })
            .then(req => {
                if (req.data.ok) {
                    return req.data;
                } else {
                    throw {
                        response: {
                            data: { detail: "Incorrect username or password" }
                        }
                    };
                }
            })
            .catch(e => {
                console.error(e, e.response.data.detail);
                const txt = e.response.data.detail
                    ? e.response.data.detail
                    : e.message;
                throw new Error(txt);
            });
    }

    async execute(
        method: "get" | "post" | "put" | "delete",
        resource: string,
        data: UserFormData | LogoutData | NewUser | null = null
    ) {
        return client
            .request({
                method,
                url: resource,
                data
            })
            .then(req => {
                return req.data;
            })
            .catch(e => {
                console.error(e, e.response.data.detail);
                const txt = e.response.data.detail
                    ? e.response.data.detail
                    : e.message;
                if (txt !== "This user does not exists.") {
                    throw new Error(txt);
                } else {
                    return e.response.data;
                }
            });
    }

    async login(user: UserFormData) {
        // let resEuskabea: UserSigninResEuskabea | null = null;
        // let resGetUser: GetUserRes | null = null;
        // let resSignin: UserSigninRes | null = null;
        // let resNewUser: NewUserRes | null = null;

        // resEuskabea = await this.loginEuskabea(user?.email, user?.password);

        // if (resEuskabea?.ok) {
        //     resGetUser = await this.execute(
        //         "get",
        //         `/user/get-user?email=${user?.email}`
        //     );
        //     if (
        //         resGetUser &&
        //         resGetUser.detail === "This user does not exists."
        //     ) {
        //         const newUser: NewUser = {
        //             identifier:
        //                 typeof resEuskabea.data.id == "string"
        //                     ? resEuskabea.data.id.startsWith("EMP") == true
        //                         ? parseInt(
        //                               resEuskabea.data.id.replace("EMP", "")
        //                           )
        //                         : parseInt(resEuskabea.data.id)
        //                     : resEuskabea.data.id,
        //             username: resEuskabea.data.username,
        //             email: resEuskabea.data.email,
        //             password: user?.password || "pa55W0rd",
        //             role: resEuskabea.data.role,
        //             cliente: resEuskabea["cliente"],
        //             idpersona: resEuskabea["idpersona"]
        //                 ? resEuskabea["idpersona"]
        //                 : 0,
        //             idioma: resEuskabea["idioma"],
        //             logo: resEuskabea["logo"],
        //             servicios: resEuskabea["servicios"]
        //         };
        //         resNewUser = await this.execute(
        //             "post",
        //             "/user/create-user",
        //             newUser
        //         );
        //         if (resNewUser && resNewUser.ok) {
        //             resSignin = await this.execute(
        //                 "post",
        //                 "/authentication/login",
        //                 user
        //             );
        //             if (resSignin && resSignin.data.token) {
        //                 this.updateCookies(resSignin.data.token);
        //                 this.updateUserRole(resEuskabea.data.role);
        //             }
        //         }
        //     } else {
        //         //Eliminar desde aquí
        //         // const userLotura: NewUser = {
        //         //     identifier:
        //         //         typeof resEuskabea.data.id == "string"
        //         //             ? resEuskabea.data.id.startsWith("EMP") == true
        //         //                 ? parseInt(resEuskabea.data.id.replace("EMP", ""))
        //         //                 : parseInt(resEuskabea.data.id)
        //         //             : resEuskabea.data.id,
        //         //     username: resEuskabea.data.username,
        //         //     email: resEuskabea.data.email,
        //         //     password: user?.password || "pa55W0rd",
        //         //     role: resEuskabea.data.role,
        //         //     cliente: resEuskabea["cliente"],
        //         //     idpersona: resEuskabea["idpersona"]
        //         //         ? resEuskabea["idpersona"]
        //         //         : 0,
        //         //     idioma: resEuskabea["idioma"],
        //         //     logo: resEuskabea["logo"],
        //         //     servicios: resEuskabea["servicios"]
        //         // };
        //         // Hasta aquí para volver a la doble autenticación
        //         resSignin = await this.execute(
        //             "post",
        //             "/authentication/login",
        //             user
        //         ); //userLotura
        //         if (resSignin && resSignin.data.token) {
        //             this.updateCookies(resSignin.data.token);
        //             this.updateUserRole(resEuskabea.data.role);
        //         }
        //     }
        // }
        const resSignin = await this.execute(
            "post",
            "/authentication/login",
            user
        );
        if (resSignin && resSignin.data.token) {
            this.updateCookies(resSignin.data.token);
        }
        return resSignin;
    }

    updateCookies(token: string) {
        const jwt = jwtDecode<Token>(token);
        this.updateUserRole(jwt.role);
        const cookieExpiresTime = new Date(
            new Date().setMinutes(new Date().getMinutes() + jwt.expires_in / 60)
        ).toUTCString();

        Vue.$cookies.set(TOKEN, token, cookieExpiresTime);
    }

    updateUserRole(role: string[]) {
        Vue.prototype.$user.set({ role: ROLE_CLIENT });

        if (role.includes(ROLE_ADMIN)) {
            Vue.prototype.$user.set({
                role: ROLE_ADMIN
            });
        }
    }

    async logout() {
        if (Vue.$cookies.isKey(TOKEN)) {
            Vue.$cookies.remove(TOKEN);
        }
    }
}

export default new AuthService();
