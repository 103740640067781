import Vue from "vue";
import Vuetify from "vuetify/lib";

const lightTheme = {
    primary: "#363636",
    secondary: "#5dad9a"
};

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: lightTheme
        }
    }
});
