import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import VueRouterUserRoles from "vue-router-user-roles";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { APIServicePlugin } from "./plugins/api-service";
import VueCookies from "vue-cookies";
import { ROLE_CLIENT, ROLE_ADMIN } from "./services/auth/auth-service";
import i18n from "./i18n";

import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


// Vue config
Vue.config.productionTip = false;
Vue.use(APIServicePlugin);
Vue.use(VueCookies);
Vue.use(VueRouterUserRoles, { router });

Vue.prototype.$user.set({ role: ROLE_CLIENT });

if (
    store.getters["auth/USER_ROLE"].length &&
    store.getters["auth/USER_ROLE"].includes(ROLE_ADMIN)
) {
    Vue.prototype.$user.set({ role: ROLE_ADMIN });
}

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
