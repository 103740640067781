import AuthService, {
    getUserState,
    UserFormData,
    UserSigninRes,
    UserState
} from "@/services/auth/auth-service";
import * as types from "../types";

interface Role {
    text: string;
    value: string;
}

const userState: UserState = getUserState();
const userRoles: Role[] = [
    { text: "Client", value: "client" },
    { text: "Administrator", value: "admin" }
];

/**
 * Auth store module
 */
const state = {
    userState,
    userRoles
};

const auth = {
    state: state,

    mutations: {
        [types.MUTATE_USER_STATE](state: State, userState: UserState) {
            state.userState = userState;
        },
        [types.MUTATE_LOGO](state: State, logo: string) {
            if (state.userState.user) {
                state.userState.user.logo = logo;
            }
        },
        [types.MUTATE_EMPRESA](state: State, Nempresa: number) {
            if (state.userState.user) {
                state.userState.user.Nempresa = Nempresa;
            }
        }
    },

    actions: {
        async [types.AUTH_LOGIN]({ commit }, userFormdata: UserFormData) {
            let userState: UserState = {
                status: { loggedIn: false },
                user: null
            };

            try {
                const user: UserSigninRes | null = await AuthService.login(
                    userFormdata
                );

                if (user) {
                    userState = getUserState();
                }
            } finally {
                commit(types.MUTATE_USER_STATE, userState);
            }
        },

        [types.AUTH_LOGOUT]({ commit }) {
            const userState: UserState = {
                status: { loggedIn: false },
                user: null
            };

            AuthService.logout();

            commit(types.MUTATE_USER_STATE, userState);
        }
    },

    getters: {
        [types.USER_IDENTIFIER](state: State) {
            return state.userState.user?.identifier;
        },
        [types.CLIENTE](state: State) {
            return state.userState.user?.cliente;
        },
        [types.IDPERSONA](state: State) {
            return state.userState.user?.idpersona;
        },
        [types.IDIOMA](state: State) {
            return state.userState.user?.idioma;
        },
        [types.LOGO](state: State) {
            return state.userState.user?.logo;
        },
        [types.SERVICIOS](state: State) {
            return state.userState.user?.servicios;
        },
        [types.EMPRESA](state: State) {
            return state.userState.user?.Nempresa;
        },
        [types.AUTH_USERNAME](state: State) {
            if (!state.userState || !state.userState.user) return "";
            return `${state.userState.user.username}`;
        },
        [types.USER_ROLE](state: State) {
            if (!state.userState || !state.userState.user) return [];
            return state.userState.user.role;
        },
        [types.ALL_ROLES](state: State) {
            return state.userRoles;
        }
    }
};

export default auth;

type State = typeof state;
