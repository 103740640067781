import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { TOKEN, ROLE_ADMIN, ROLE_CLIENT } from "@/services/auth/auth-service";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        component: () => import("@/pages/index2.vue"),
        children: [
            {
                name: "Precios de Servicios",
                path: "prices",
                component: () => import("@/pages/Prices.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: false
                        },
                        {
                            role: ROLE_CLIENT,
                            access: true
                        }
                    ]
                }
            },
            {
                name: "Área Clientes",
                path: "installation",
                component: () => import("@/pages/AreaClientes.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: true
                        }
                    ]
                }
            },
            {
                name: "Avanzado",
                path: "advanced",
                component: () =>
                    import("@/pages/analitico-predictivo/Avanzado.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: true
                        }
                    ]
                }
            },
            {
                name: "Compendio de Instalación",
                path: "resumen",
                component: () =>
                    import("@/pages/analitico-predictivo/Resumen.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: true
                        }
                    ]
                }
            },
            {
                name: "Consumos Servicios",
                path: "energy",
                component: () =>
                    import("@/pages/analitico-predictivo/Energy.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: true
                        }
                    ]
                }
            },
            {
                name: "Costes con Servicios",
                path: "costes",
                component: () => import("@/pages/gestor-energetivo/Costes.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: true
                        }
                    ]
                }
            },
            {
                name: "Resumen con Servicios",
                path: "summary",
                component: () =>
                    import("@/pages/gestor-energetivo/Summary.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: true
                        }
                    ]
                }
            },
            {
                name: "Formulario Ahorro",
                path: "form",
                component: () =>
                    import("@/pages/gestor-energetivo/FormularioAhorro.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: true
                        }
                    ]
                }
            },
            {
                name: "Proyecto Ahorro",
                path: "saving",
                component: () =>
                    import("@/pages/gestor-energetivo/ProyectoAhorro.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: true
                        }
                    ]
                }
            },
            {
                name: "Contador de Energia",
                path: "contador",
                component: () => import("@/pages/desarrollo/Monaguillos.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: true
                        }
                    ]
                }
            },
            {
                name: "Detalle Consumo por Periodos",
                path: "consdia",
                component: () => import("@/pages/desarrollo/ConsDia.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "ingestador CSV",
                path: "ingestor",
                component: () => import("@/pages/desarrollo/IngestadorCSV.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "Datadis",
                path: "datadis",
                component: () => import("@/pages/desarrollo/datadis.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },

            {
                name: "Traductor",
                path: "translate",
                component: () => import("@/pages/desarrollo/TranslateDemo.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "Mapa de Instalaciones",
                path: "map",
                component: () => import("@/pages/desarrollo/Maps.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "Logs",
                path: "logs",
                component: () => import("@/pages/desarrollo/Logs.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "Elementos Compuestos",
                path: "algebra",
                component: () => import("@/pages/desarrollo/Algebra.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "Instalacion",
                path: "instalation",
                component: () => import("@/pages/desarrollo/Instalacion.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "Prediccion",
                path: "prediction",
                component: () => import("@/pages/desarrollo/Prediccion.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "I-frame",
                path: "external",
                component: () => import("@/pages/desarrollo/Iframe.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "Forecasting",
                path: "forecast",
                component: () => import("@/pages/desarrollo/forecasting.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "User Management",
                path: "user-management",
                component: () => import("@/pages/UserManagement.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: true
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false,
                            redirect: "Signin"
                        }
                    ]
                }
            },
            {
                name: "Administrador de cajas",
                path: "boxes",
                component: () => import("@/pages/desarrollo/AdminCajas.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: false
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false
                        }
                    ]
                }
            },
            {
                name: "Administrador de elementos",
                path: "elements",
                component: () => import("@/pages/desarrollo/AdminElementos.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: false
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false
                        }
                    ]
                }
            },
            {
                name: "Euskabea ISO",
                path: "euskabea",
                component: () => import("@/pages/desarrollo/EuskabeaISO.vue"),
                meta: {
                    permissions: [
                        {
                            role: ROLE_ADMIN,
                            access: false
                        },
                        {
                            role: ROLE_CLIENT,
                            access: false
                        }
                    ]
                }
            }
        ]
    },
    {
        path: "/signin",
        name: "Signin",
        component: () => import("@/pages/auth/SigninForm.vue")
    },
    {
        path: "/euskabea",
        name: "Euskabea",
        component: () => import("@/pages/auth/SigninEuskabea.vue")
    },
    {
        path: "/prodesa",
        name: "Prodesa",
        component: () => import("@/pages/auth/SigninProdesa.vue")
    },
    {
        path: "/pasaiaport",
        name: "Pasaiaport",
        component: () => import("@/pages/auth/SigninPasaiaport.vue")
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    const publicPages = ["/signin", "/euskabea", "/prodesa", "/pasaiaport"];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = Vue.$cookies.isKey(TOKEN);

    // Trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next("/signin");
    } else {
        next();
    }
});

export default router;
